import React, { useContext, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseStylesheet, { FontStylesheet } from '@farewill/ui/base'
import { P } from '@farewill/ui'

import { NOTICE_BANNER_ENABLED, COOKIE_BANNER_ENABLED } from 'config'
import PageContext from 'PageContext'
import useParameterTracking from 'lib/tracking/useParameterTracking'
import PreventOrphan from 'components/PreventOrphan'
import { LIVE_PRODUCTS } from 'lib/products/constants'
import SEO from 'components/SEO'
import LiveChatFront from 'components/LiveChat'
import { headerTypeMap, headerTypes } from 'components/Header/types'
import { footerTypeMap, footerTypes } from 'components/Footer/types'
import CookieBanner from 'components/CookieBanner'
import NoticeBanner from 'components/NoticeBanner'
import { CookieNames } from 'lib/storage/cookies/constants'
import { getCookie } from 'lib/storage/cookies/helpers'
import SkipToContent from 'components/SkipToContent'
import DefaultLayoutContext from './context'

const StyledPageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-bottom: ${({ cookieBannerShown, cookieBannerHeight }) =>
    !COOKIE_BANNER_ENABLED && cookieBannerShown
      ? `${cookieBannerHeight}px`
      : 0};

  // browser hack for IE11 to stop issues with page flex
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
`

const StyledPageContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $alignContent }) =>
    $alignContent === 'start' ? 'flex-start' : 'center'};

  > * {
    width: 100%;
  }
`

const DefaultLayout = ({
  branding,
  children,
  title,
  description,
  shareImage,
  meta,
  canonicalPath,
  product,
  showLiveChatFront,
  showFuneralPlansLiveChatFront,
  telephoneNumber,
  email,
  usePhoneNumberTracking,
  alignContent,
  utmParameters,
  headerType,
  mobileHeaderColor,
  headerColor,
  headerHideFarewillLogo,
  headerFarewillLogoLink,
  headerShowLoginLink,
  headerHideTelephoneNumber,
  headerStyle,
  footerType,
  footerShowFwlsDisclaimer,
  footerShowStandardisedPriceList,
  footerShowFuneralPlansLegalInformation,
  footerShowSelectedProductLinks,
}) => {
  const {
    product: pageProduct,
    loadedTracking,
    trackedPhoneNumber,
  } = useContext(PageContext)
  const selectedProduct = product || pageProduct
  useParameterTracking(utmParameters, branding)
  const mainContentRef = useRef()
  const cookieRef = React.useRef()
  const [cookieBannerHeight, setCookieBannerHeight] = useState(0)
  const [cookieBannerShown, setCookieBannerShown] = useState(false)
  const [isFrontPage, setIsFrontPage] = useState(false)

  useEffect(() => {
    if (COOKIE_BANNER_ENABLED) return

    setCookieBannerShown(!getCookie(CookieNames.COOKIE_BANNER_HIDDEN))
  }, [])

  useEffect(() => {
    if (COOKIE_BANNER_ENABLED) return () => {}

    const calculateContentHeight = () => {
      setCookieBannerHeight(cookieRef?.current?.clientHeight || 0)
    }
    calculateContentHeight()
    window.addEventListener('resize', calculateContentHeight)
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [cookieRef])

  useEffect(() => {
    const pathName = window.location.pathname
    if (pathName === '/') {
      setIsFrontPage(true)
    } else {
      setIsFrontPage(false)
    }
  })

  const Header = headerTypeMap[headerType]
  const Footer = footerTypeMap[footerType]

  return (
    <DefaultLayoutContext.Provider
      value={{ loadedTracking, trackedPhoneNumber, usePhoneNumberTracking }}
    >
      <SEO
        title={title}
        description={description}
        shareImage={shareImage}
        meta={meta}
        canonicalPath={canonicalPath}
      />

      <FontStylesheet assetFolder="/assets/" />
      <BaseStylesheet />

      <StyledPageWrapper
        cookieBannerShown={cookieBannerShown}
        cookieBannerHeight={cookieBannerHeight}
      >
        {(showLiveChatFront || showFuneralPlansLiveChatFront) && (
          <LiveChatFront
            hasCookieBanner={cookieBannerShown}
            funeralPlansChat={!!showFuneralPlansLiveChatFront}
          />
        )}

        {!COOKIE_BANNER_ENABLED && cookieBannerShown && (
          <CookieBanner
            setCookieBannerShown={setCookieBannerShown}
            ref={cookieRef}
          />
        )}

        <SkipToContent
          color={headerColor}
          mobileColor={mobileHeaderColor}
          mainContentRef={mainContentRef}
        >
          Skip navigation
        </SkipToContent>

        {NOTICE_BANNER_ENABLED && isFrontPage && (
          <NoticeBanner>
            <P>
              <PreventOrphan>
                Winners of Best Direct Cremation Provider and Best Low Cost
                Funeral Provider at the Good Funeral Awards 2022
              </PreventOrphan>
            </P>
          </NoticeBanner>
        )}
        {headerType && (
          <Header
            mobileColor={mobileHeaderColor}
            color={headerColor}
            selectedProduct={selectedProduct}
            showLoginLink={headerShowLoginLink}
            telephoneNumber={telephoneNumber}
            hideFarewillLogo={headerHideFarewillLogo}
            headerFarewillLogoLink={headerFarewillLogoLink}
            hideTelephoneNumber={headerHideTelephoneNumber}
            headerStyle={headerStyle}
          />
        )}

        <StyledPageContent
          $alignContent={alignContent}
          id="main-content"
          ref={mainContentRef}
        >
          {children}
        </StyledPageContent>

        {footerType && (
          <Footer
            selectedProduct={selectedProduct}
            showSelectedProductLinks={footerShowSelectedProductLinks}
            telephoneNumber={telephoneNumber}
            showFwlsDisclaimer={footerShowFwlsDisclaimer}
            showStandardisedPriceList={footerShowStandardisedPriceList}
            showFuneralPlansLegalInformation={
              footerShowFuneralPlansLegalInformation
            }
            email={email}
          />
        )}
      </StyledPageWrapper>
    </DefaultLayoutContext.Provider>
  )
}

DefaultLayout.propTypes = {
  branding: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  shareImage: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.oneOf(LIVE_PRODUCTS),
  showLiveChatFront: PropTypes.bool,
  showFuneralPlansLiveChatFront: PropTypes.bool,
  canonicalPath: PropTypes.string,
  telephoneNumber: PropTypes.string,
  email: PropTypes.string,
  usePhoneNumberTracking: PropTypes.bool,
  alignContent: PropTypes.oneOf(['start', 'center']),
  utmParameters: PropTypes.shape({
    utmCampaign: PropTypes.string,
    utmMedium: PropTypes.string,
    utmSource: PropTypes.string,
    utmChannel: PropTypes.string,
  }),
  headerType: PropTypes.oneOf([null, ...headerTypes]),
  mobileHeaderColor: PropTypes.string,
  headerColor: PropTypes.string,
  headerHideFarewillLogo: PropTypes.bool,
  headerShowLoginLink: PropTypes.bool,
  headerFarewillLogoLink: PropTypes.string,
  headerStyle: PropTypes.oneOf(['default', 'funeralPlanSticky']),
  headerHideTelephoneNumber: PropTypes.bool,
  footerType: PropTypes.oneOf([null, ...footerTypes]),
  footerShowFwlsDisclaimer: PropTypes.bool,
  footerShowStandardisedPriceList: PropTypes.bool,
  footerShowFuneralPlansLegalInformation: PropTypes.bool,
  footerShowSelectedProductLinks: PropTypes.bool,
}

DefaultLayout.defaultProps = {
  branding: undefined,
  title: undefined,
  description: undefined,
  shareImage: undefined,
  meta: undefined,
  canonicalPath: undefined,
  product: null,
  showLiveChatFront: false,
  showFuneralPlansLiveChatFront: false,
  telephoneNumber: '',
  email: null,
  usePhoneNumberTracking: true,
  alignContent: 'center',
  utmParameters: undefined,
  headerType: 'full',
  mobileHeaderColor: null,
  headerColor: null,
  headerHideFarewillLogo: false,
  headerShowLoginLink: false,
  headerFarewillLogoLink: undefined,
  headerHideTelephoneNumber: false,
  headerStyle: 'default',
  footerType: 'full',
  footerShowFwlsDisclaimer: false,
  footerShowStandardisedPriceList: false,
  footerShowFuneralPlansLegalInformation: false,
  footerShowSelectedProductLinks: true,
}

export default DefaultLayout
