import React, { PropsWithChildren } from 'react'
import { isInternalURL } from 'lib/url/isInternalURL'

export type ExternalLinkProps = {
  className?: string
  href: string
  ariaLabel?: string
  children: React.ReactNode
}

const ExternalLink = ({
  className = '',
  href,
  children,
  ariaLabel = '',
}: PropsWithChildren<ExternalLinkProps>) => {
  // The reason we are not using Link component from Next.js is because
  // we have separate apps in subdomains, that we want to open in the same
  // tab, and not in the same app.
  const isInternalLink = isInternalURL(href)
  return isInternalLink ? (
    <a className={className} href={href} aria-label={ariaLabel}>
      {children}
    </a>
  ) : (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel}
    >
      {children}
    </a>
  )
}

export default ExternalLink
