import {
  CREMATION_FUNERAL_PLAN_FEE,
  CREMATION_SIMPLE_FEE,
  FUNERAL_AVERAGE_COST_IN_UK,
  CREMATION_DELIVERY_FEE,
  CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE,
  CREMATION_FUNERAL_PLAN_INSTALMENT_FEE,
  CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE,
  CREMATION_DOCTORS_FEE,
  CREMATION_URGENT_COLLECTION_FEE,
  CREMATION_MEDICAL_DEVICE_REMOVAL_FEE,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

export const FORMATTED_DIRECT_CREMATION_PRICE =
  formatPriceInPounds(CREMATION_SIMPLE_FEE)

export const FORMATTED_DIRECT_CREMATION_DELIVERY_PRICE = formatPriceInPounds(
  CREMATION_DELIVERY_FEE
)

export const FORMATTED_CREMATION_DOCTORS_FEE = formatPriceInPounds(
  CREMATION_DOCTORS_FEE
)

export const FORMATTED_CREMATION_FUNERAL_PLAN_FEE = formatPriceInPounds(
  CREMATION_FUNERAL_PLAN_FEE
)

export const FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE =
  formatPriceInPounds(CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE)

export const FORMATTED_CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE =
  formatPriceInPounds(CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE)

export const FORMATTED_CREMATION_FUNERAL_PLAN_INSTALMENT_FEE =
  formatPriceInPounds(CREMATION_FUNERAL_PLAN_INSTALMENT_FEE)

export const FORMATTED_CREMATION_MEDICAL_DEVICE_REMOVAL_FEE =
  formatPriceInPounds(CREMATION_MEDICAL_DEVICE_REMOVAL_FEE)

export const FORMATTED_CREMATION_DELIVERY_FEE = formatPriceInPounds(
  CREMATION_DELIVERY_FEE
)

export const FORMATTED_CREMATION_URGENT_COLLECTION_FEE = formatPriceInPounds(
  CREMATION_URGENT_COLLECTION_FEE
)

export const FORMATTED_FUNERAL_AVERAGE_COST_IN_UK = formatPriceInPounds(
  FUNERAL_AVERAGE_COST_IN_UK
)

const FIRM_REFERENCE_NUMBER = '995901'

export const FUNERAL_PLANS_DISCLOSURE_STATEMENT = `Farewill Funerals Ltd is a company registered in England No. 12278192. Registered office: 1st Floor, 27 Downham Road, London, England, N1 5AA. Farewill Funerals Ltd is authorised and regulated by the Financial
Conduct Authority under Firm Reference Number ${FIRM_REFERENCE_NUMBER}.
Please note that the selling and administering of funeral plans is regulated
by the Financial Conduct Authority (FCA), however if you are purchasing a
funeral without a funeral plan this will not be covered by FCA regulation.`
